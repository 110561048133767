import styled from 'styled-components'
import {Offcanvas, Navbar, Nav} from 'react-bootstrap'
import { Link } from "gatsby"


export const SideNavContainer = styled(Offcanvas)`
    background: #111111;
`

export const NavbarStyled = styled(Navbar)`
    padding: 0 !important;
    border: 0;
    // border-bottom: ${({theme}) => theme.borderLine} 1px solid;
    color: ${({theme}) => theme.text} !important;
    max-height: max-content;
`

export const NavLink = styled(Link)`
    color: ${({theme}) => theme.text} !important;
    font-size: 14px;
    white-space: nowrap;
`

export const NavStyled = styled(Nav)`
    display: inline-flex;
    flex-direction: row;
    justify-content: between;
    flex-wrap: nowrap;
    width: 100vw;
    overflow-x: auto;
`