import { atom } from 'recoil'

export const themeState = atom({ 
    key: 'themeState', 
    default: 'dark'
})

export const sideNavAtom = atom({
    key: 'sideNav',
    default: false
});