exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-collections-shopify-collection-handle-jsx": () => import("./../../../src/pages/collections/{ShopifyCollection.handle}.jsx" /* webpackChunkName: "component---src-pages-collections-shopify-collection-handle-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-policy-authenticity-jsx": () => import("./../../../src/pages/policy/authenticity.jsx" /* webpackChunkName: "component---src-pages-policy-authenticity-jsx" */),
  "component---src-pages-policy-privacy-jsx": () => import("./../../../src/pages/policy/privacy.jsx" /* webpackChunkName: "component---src-pages-policy-privacy-jsx" */),
  "component---src-pages-policy-shipping-jsx": () => import("./../../../src/pages/policy/shipping.jsx" /* webpackChunkName: "component---src-pages-policy-shipping-jsx" */),
  "component---src-pages-policy-terms-jsx": () => import("./../../../src/pages/policy/terms.jsx" /* webpackChunkName: "component---src-pages-policy-terms-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-products-shopify-product-product-type-index-jsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/index.jsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-index-jsx" */),
  "component---src-pages-products-shopify-product-product-type-shopify-product-handle-jsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-shopify-product-handle-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-track-jsx": () => import("./../../../src/pages/track.jsx" /* webpackChunkName: "component---src-pages-track-jsx" */),
  "component---src-pages-vendor-shopify-product-vendor-jsx": () => import("./../../../src/pages/vendor/{ShopifyProduct.vendor}.jsx" /* webpackChunkName: "component---src-pages-vendor-shopify-product-vendor-jsx" */)
}

