import { createGlobalStyle} from "styled-components"

export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
  }
  
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    // background: #0f0f002d;
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: #343434;
    border-radius: 8px;
    border: none;
  }

  *::-webkit-scrollbar-thumb:hover {
    background: #343434;
  }

  html{
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: ${({theme}) => theme.fonts.font2};
    transition: all 0.001s linear;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    touch-action: manipulation;
  }

  pre, code, kbd, samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
    font-size: 1em;
  }

  a{
    text-decoration: none;
  }

  hr {
    border-top-width: 1px;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  input{
    background: ${({theme}) => theme.inputBg.elev1};
    
  }

  `