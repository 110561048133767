import { useEffect } from 'react';
import { useRecoilState, useRecoilCallback } from 'recoil'
import { themeState } from '../AtomStates'

export const useDarkMode = () => {
  const [theme, setTheme] = useRecoilState(themeState)
  const setMode = useRecoilCallback(() => (mode) => {
    window.localStorage.setItem('theme', mode)
    setTheme(mode)
  },[setTheme]);

  const toggleTheme = () => {
    theme === 'light' ? setMode('dark') : setMode('light')
  };

  const toggleLogic = useRecoilCallback(()=> () => {
    const localTheme = window.localStorage.getItem('theme');
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && 
        !localTheme ? setMode('dark') :
        localTheme ? setTheme(localTheme) : setMode('light');
  },[setTheme, setMode])

  useEffect(() => {
    toggleLogic()
  }, [toggleLogic]);

  return [theme, toggleTheme]
};