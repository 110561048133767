import styled from 'styled-components'
import {Link} from 'gatsby'
import {Container, Row} from 'react-bootstrap'

export const FooterStyled = styled(Container)`
    font-family: ${({theme}) => theme.fonts.font2};
    background: ${({theme}) => theme.cardBg};
    padding: 2em 2em 2em 2em;
    margin-top: 4em;
    font-weight: 300;
`;

export const Gst = styled.span`
    font-size: 12px;
`;

export const SocTitle = styled.span`
    font-weight: 700;
    font-size: 14px;
    display: block;
    opacity: 0.9;
`;

export const SocLink = styled.a`
    font-size: 16px;
    display: block;
    color: ${({theme}) => theme.text};

    &:hover{
        opacity: 0.5;
        color: ${({theme}) => theme.text};
    }
`;

export const FooterCopRow = styled(Row)`
    font-size: 14px;
`;

export const PolicyLinks = styled(Link)`
    color: ${({theme}) => theme.text};
    margin: 0 1em;

    &:hover{
        color: ${({theme}) => theme.text};
        opacity: 0.6;
    }
`;

export const SectionTitle = styled.span`
    font-weight: 500;
    font-size: 1rem;
    display: block;
    opacity: 0.8;
`;

export const PaymentsLogo = styled.div`
    display: block;

    .paymentLogo-item{
        background: white;
        padding: 0.5em;
        margin: 0.2em;
        display: inline-flex;
        border-radius: 10px;
        user-select: none;
        user-drag: none;
    }
`;