import React from 'react'
import {RecoilRoot} from 'recoil'
import {ApolloClient, ApolloProvider, InMemoryCache, HttpLink} from '@apollo/client'
import fetch from 'isomorphic-fetch'
import { StoreProvider } from "./src/context/store-context"
import "@fontsource/reem-kufi/400.css";
import "@fontsource/reem-kufi/500.css";
import "@fontsource/reem-kufi/600.css";
import "@fontsource/reem-kufi/700.css";
import "@fontsource/quicksand/300.css";
import "@fontsource/quicksand/400.css";
import "@fontsource/quicksand/500.css";
import "@fontsource/quicksand/600.css";
import "@fontsource/quicksand/700.css";
import './custom.scss'
import Layout from './src/components/layout'
import { themeState } from './src/components/AtomStates'
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/thumbs";

import {ThemeProvider} from "styled-components"
import {lightTheme, darkTheme} from './src/components/theme/theme'
import {GlobalStyles} from './src/styles/globalStyles'

import {useDarkMode} from './src/components/theme/useDarkMode'

const httpLink = new HttpLink({
    uri: process.env.GATSBY_RAYHAN_API,
    fetch
})
const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
  });

const initializeRecoilState = () => ({set}) => set(themeState, 'dark')

const ThemeProviderCom = ({children}) => {
    const [theme] = useDarkMode()
    const themeMode = theme === 'dark' ? darkTheme : lightTheme;

    return(
        <ThemeProvider theme={themeMode}>
            <GlobalStyles />
            {children}
        </ThemeProvider>
    )
}


export const wrapPageElement = ({element, props}) => (
    <RecoilRoot initializeState={initializeRecoilState}>
        <ApolloProvider client={client}>
            <StoreProvider>
                <ThemeProviderCom>
                    <Layout {...props}>{element}</Layout>
                </ThemeProviderCom>
            </StoreProvider>
        </ApolloProvider>
    </RecoilRoot>
)