import React from 'react'
import { StoreContext } from "../../context/store-context"
import {useRecoilState} from 'recoil'
import {NavbarStyled, NavLink, NavAnchor, TextLogo, CustomLink} from './navbar.styled'
import {SideNav} from './sidenav'
import { sideNavAtom } from '../AtomStates'
import Toggle from '../theme/themeToggler'
import {useDarkMode} from '../theme/useDarkMode'

import NavSearch from './NavSearch'

import { SearchProvider } from '../../context/search-provider'

import { Icon } from '@iconify/react';

const Navbar = () => {
    const [theme, toggleTheme] = useDarkMode()
    const { checkout } = React.useContext(StoreContext)
    // const { checkout, loading, didJustAddToCart } = React.useContext(StoreContext)

    const items = checkout ? checkout.lineItems : []

    const quantity = items.reduce((total, item) => {
        return total + item.quantity
    }, 0)

        return(
            <>
                <NavbarStyled className="navbar navbar-expand-lg">
                    <CollapsibleBtn />
                    <TextLogo className="navbar-brand d-flex flex-column me-2 me-md-4" to="/">
                        <span>RAYHAN</span>
                        <small>Online Attar Shop</small>
                    </TextLogo>
                    
                    <NavbarNav left className="d-none d-md-flex">
                        <NavItem nlink>
                            <SearchProvider>
                                <NavSearch />
                            </SearchProvider>
                        </NavItem>
                    </NavbarNav>

                    <NavbarNav right>
                        <Toggle right theme={theme} toggleTheme={toggleTheme} sm="12" md="6" className="d-none d-md-flex" iconSize="20px"/>

                        <NavItem anchor linkto="https://signupforservices.com/whatsapp/optin/?bId=38de1476-8f10-4e82-a959-6de3401c39aa&bName=Rayhan&s=URL&lang=en_US" className="d-none d-md-flex">
                            <Icon className="me-2" icon="bi:whatsapp" width="24px" height="24px" />
                            <span>9566284128</span>
                        </NavItem>
                        <NavItem ariaLabel={'Track you order'} linkto="/track" className="d-none d-md-flex">
                            <Icon icon="emojione-v1:delivery-truck" width="24px" height="24px" />
                        </NavItem>
                        <NavItem className="cartBtn" ariaLabel={`Shopping Cart with ${quantity} items`} linkto="/cart">
                            <Icon className="" icon="ion:cart-outline" width="24px" height="24px" /> 
                            <span>{quantity > 0 && <div>x {quantity}</div>}</span>
                        </NavItem>
                        {/* <NavItem anchor ariaLabel={'Customer Login page'} className="d-none d-md-flex" linkto="/login">
                            <Icon className="me-2" icon="ion:logInOutline" width="24px" height="24px" />
                            <span>Login</span>
                        </NavItem> */}
                    </NavbarNav>
                </NavbarStyled>
                <SideNav />
            </>
        )
    
}

const CollapsibleBtn = () => {
    const [,setShow] = useRecoilState(sideNavAtom);
    const handleShow = () => setShow(true)
    
    return(
        <CustomLink className="text-light collapsed d-flex align-items-center me-4" onClick={handleShow} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <Icon icon="gg:menu-left" width="24px" height="24px" />
        </CustomLink>
    )
}

const NavbarNav = ({left, className, children}) => {
    
    return(
        left ?
        <ul className={`navbar-nav me-auto flex-row align-items-center ${className}`}>
            {children}
        </ul> : 
        <ul className={`navbar-nav ms-auto d-flex flex-row align-items-center  ${className}`}>
            {children}
        </ul>
    )
}

const NavItem = ({nlink, ariaLabel, anchor, linkto, children, className}) => {
    
    return(
        nlink ? 
        <li className={`nav-item ${className}`}>
            <div className="d-flex align-items-center" aria-label={ariaLabel}>{children}</div>
        </li> : anchor ?
        <li className={`nav-item ${className}`}>
            <NavAnchor className="d-flex align-items-center" href={linkto} rel="noopener noreferrer" target="_blank" aria-label={ariaLabel}>{children}</NavAnchor>
        </li> :
        <li className={`nav-item ${className}`}>
            <NavLink className="d-flex align-items-center" to={linkto} aria-label={ariaLabel}>{children}</NavLink>
        </li> 
    )
}

export default Navbar