import React from 'react'
import { Seo } from '../helmet/seo'
import NavHeader from '../navbar'
import Footer from '../footer/index'

const Layout = (props) => {
    return(
        <>
            <Seo />
            <NavHeader />
                {props.children}
            <Footer></Footer>
        </>
    )
}

export default Layout