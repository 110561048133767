import React from 'react';
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { func, string } from 'prop-types';

import { Icon } from '@iconify/react';

const NavLink = styled(Button)`
    color: ${({theme}) => theme.text} !important;
    background: ${({theme}) => theme.btn.default};
    border-radius: 10px;
    border: 0;
    height: 48px;
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
    text-decoration: none;
    margin: auto 0.5rem; 

    &:hover{
        background: ${({theme}) => theme.accent.primary.color};
        color:${({theme}) => theme.accent.primary.text} !important;
    }
`

const Toggle = ({theme, toggleTheme, iconSize}) => {
    return(
        <li className="nav-item">
            <NavLink className="d-flex align-items-center d-none d-md-flex" onClick={toggleTheme} aria-label="Toggle Theme" >
                <Icon icon="ion:sunny-outline" width="24px" height="24px" />
            </NavLink>
        </li>
    )
}

Toggle.propTypes = {
    toggleTheme: func.isRequired,
    theme: string,
    iconSize: string
  }

export default Toggle