import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useQuery } from 'urql'
import gql from 'graphql-tag'
import { Link } from 'gatsby'
import { globalHistory } from '@reach/router'

import {Form, Row, Col, Badge } from 'react-bootstrap'
import {SearchBarContainer, SearchBar, SearchResult, ProductVendor, ProductTitle, ProductPrice, SearchInfo} from './navbar.styled'

const NavSearch_Query = gql`
query ($filter: String!) {
    products(
      query: $filter,
      first: 5,
      sortKey: TITLE
    ) {
      edges {
        node {
          title
          vendor
          productType
          handle
          priceRangeV2: priceRange {
            minVariantPrice {
              currencyCode
              amount
            }
          }
          id
        }
      }
    }
  }
`
const NavSearch = () => {
    const [filter, setFilter] = useState('title: *')
    const [isSearchResultOpen, setIsSearchResultOpen] = useState(false)
    const [result, executeQuery] = useQuery({
      query: NavSearch_Query,
      variables: { filter },
      pause: !filter
    })
    const search = useCallback((value) => {
      setFilter(`title: ${value}`)
      executeQuery()
    }, [executeQuery])

    const {data, fetching, error} = result

    const ref = useRef()
    useOnClickOutside(ref, () => setIsSearchResultOpen(false));

    useEffect(() => {
      return globalHistory.listen(({ action }) => {
        action === 'PUSH' && isSearchResultOpen && setIsSearchResultOpen(false)
      })
    })

    return(
      <SearchBarContainer>
        <Form className="w-100">
          <Form.Group className="w-100 m-0" controlId="formSearch">
            <SearchBar type="text" autoComplete="off" placeholder="Seach i.e. Zafrani Shamama" 
            onFocus={() => setIsSearchResultOpen(true)} 
            onChange={e => search(e.target.value)} />
          </Form.Group>
        </Form>
        {isSearchResultOpen &&
        <SearchResult ref={ref} className="justify-content-center">
          <Row className="w-100">
          {fetching ? <span>Searching ...</span> :
          data ? data.products.edges.map((item, index) => {
            return(
              <Col key={item.node.handle} xs={12}>
                <Link key={index} to={`/products/${item.node.productType.toLowerCase()}/${item.node.handle}`} className="d-flex flex-column">
                  <ProductTitle>{item.node.title}</ProductTitle>
                  <Row className="d-flex flex-row justify-content-between">
                    <Col xs={9} className="d-flex flex-row">
                      <small><Badge bg="primary" className="me-2">{item.node.productType}</Badge></small>
                      {/* <ProductType className="me-2">Type: {item.node.productType}</ProductType> */}
                      <ProductVendor>Brand: {item.node.vendor}</ProductVendor>
                    </Col>
                    <Col xs={3} className="d-flex justify-content-end">
                      <ProductPrice>₹ {item.node.priceRangeV2.minVariantPrice.amount}</ProductPrice>
                    </Col>
                  </Row>
                </Link>
              </Col>
            )
          }) : error ? <SearchInfo error>Search Failed: {error}</SearchInfo> : <SearchInfo empty>Type Product Title</SearchInfo>}
          </Row>
        </SearchResult>
        }
      </SearchBarContainer>
    )
}

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export default NavSearch