import React from 'react'
import Navbar from './navbar'
// import NavMessage from './navMessage'
// import NavbarSecond from './navlinks'

const NavHeader = () => {
    return(
        <header>
            {/* <NavMessage /> */}
            <Navbar />
            {/* <NavbarSecond /> */}
        </header>
    )
}

export default NavHeader