const white = {
    elev1: '#f0f2f5',
    elev2: '#f1f3f8',
    elev3: '#fdfdfd'
}
const black = {
    elev1: '#181818',
    elev2: '#212121',
    elev3: '#292929'
}

export const lightTheme = {
    body: white.elev1,
    text: '#363537',
    textAlt: '#e4e6eb',
    icons: '#363537',
    toggleBorder: '#FFF',
    gradient: 'linear-gradient(#39598A, #79D7ED)', 
    cardBg: '#ffffff',
    navbarBg: '#ffffff',
    inputBg: {
        elev1: white.elev1,
        elev2: white.elev1,
        elev3: white.elev1
    },
    borderLine: '#dbdbdb',
    shadow: '10px 10px 20px #d9d9d9, -10px -10px 20px #ffffff',
    collapseBg: '#ffffff',
    accordion:{
        headerBg: '#6610f2',
        text: '#e4e6eb',
        body: '#ffffff'
    },
    btn:{
        default: '#ffffff'
    },
    fonts:{
        font1: `'Reem Kufi', sans-serif`,
        font2: `'Quicksand', sans-serif;`
    },
    accent: {
        black:{
            elev1: '#212121',
            elev2: '#292929'
        },
        primary: {
            color: '#6610f2',
            text: '#ffffff'
        }
    },
    typo:{
        h1: '1.8rem',
        h2: '1.8rem'
    }
}
  
export const darkTheme = {
    body: '#181818',
    text: '#e4e6eb',
    textAlt: '#363537',
    icons: '#e4e6eb',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#091236, #1E215D)',
    cardBg: black.elev2,
    inputBg: {
        elev1: black.elev1,
        elev2: black.elev2,
        elev3: black.elev3
    },
    borderLine: '#3e4042',
    shadow: '10px 10px 20px #18191a, -10px -10px 20px #111',
    accordion:{
        headerBg: black.elev2,
        text: '#e4e6eb',
        body: '#111111'
    },
    btn:{
        default: black.elev2
    },
    fonts:{
        font1: `'Reem Kufi', sans-serif`,
        font2: `'Quicksand', sans-serif;`
    },
    accent: {
        black:{
            elev1: black.elev2,
            elev2: black.elev3
        },
        primary: {
            color: '#ffed99',
            text: black.elev2
        }
    },
    typo:{
        h1: '1.8rem',
        h2: '1.8rem'
    }
}