import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import {Link} from 'gatsby'

export const TextLogo = styled(Link)`
    font-size: 18px;
    font-weight: 600;
    color: ${({theme}) => theme.text} !important;
    margin-right: 1rem;
    margin-left: 0 ;
    user-select: none;
    
    small{
        font-size: 14px;
        font-family: ${({theme}) => theme.fonts.font2};
        font-weight: 400;
    }
`;

export const NavbarStyled = styled.nav`
    font-family: ${({theme}) => theme.fonts.font1};
    border: 0;
    color: ${({theme}) => theme.text} !important;
    padding: 1rem 1rem !important;
    justify-content: start;
`;

export const NavLink = styled(Link)`
    color: ${({theme}) => theme.text} !important;
    background: ${({theme}) => theme.btn.default};
    border-radius: 10px;
    height: 48px;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    text-decoration: none;
    margin: auto 0.5rem;

`;

export const NavAnchor = styled.a`
    color: ${({theme}) => theme.text} !important;
    background: ${({theme}) => theme.btn.default};
    border-radius: 10px;
    height: 48px;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    text-decoration: none;
    margin: auto 0.5rem;
`;

export const CustomLink = styled.button`
    color: ${({theme}) => theme.text} !important;
    background: ${({theme}) => theme.btn.default};
    border-radius: 10px;
    height: 48px;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    text-decoration: none;
    margin: auto 0.5rem;
`;

export const SearchBarContainer = styled.div`
    position: relative;
`

export const SearchBar = styled(Form.Control)`
    background: ${({theme}) => theme.inputBg.elev2} !important;
    color: ${({theme}) => theme.text} !important;
    border: 0;
    border-radius: 10px;
    width: 40vw;
    height: 48px;
    font-size: 0.8em;
`;

export const SearchResult = styled.div`
    font-family: ${({theme}) => theme.fonts.font2};
    background: ${({theme}) => theme.inputBg.elev2} !important;
    color: ${({theme}) => theme.text} !important;
    position: absolute;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    top: 110%;
    width: 40vw;
    border-radius: 10px;
    min-height: 50px;
    max-height: auto;
    z-index: 999;

    a{
        color: ${({theme}) => theme.text} !important;
        padding: 1rem 1rem;
        border-radius: 10px;
        &:hover{
            background: ${({theme}) => theme.accent.black.elev2} !important;
        }
    }
`

export const ProductTitle = styled.span`
    font-weight: 700;
    font-size: 18px;
`

export const ProductType = styled.span`
    font-size: 14px;
    border-radius: 10px;
`

export const ProductVendor = styled.span`
    opacity: 0.5;
    font-size: 14px;
`

export const ProductPrice = styled.span`
    font-size: 18px;
    font-weight: 500;
`

export const SearchInfo = styled.span`
    font-size: 14px;
    font-weight: 500;
    opacity: 0.8;
    color: ${props => props.error ? 'yellow' : props.empty ? 'white' : 'white'}
`