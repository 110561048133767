import React from 'react'
import { useRecoilState } from 'recoil'
import { Nav} from 'react-bootstrap'
import slugify from "@sindresorhus/slugify"
import { graphql, useStaticQuery } from "gatsby"
import { sideNavAtom } from '../../AtomStates'

import {SideNavContainer, NavLink} from './sidenav.styled'

export const SideNav = () => {
    const [show, setShow] = useRecoilState(sideNavAtom);
    const handleClose = () => setShow(false)

    const {allShopifyProduct: { productTypes },
  } = useStaticQuery(graphql`
        query {
          allShopifyProduct {
            productTypes: distinct(field: productType)
          }
        }
      `)
    return(
        <SideNavContainer show={show} onHide={handleClose} scroll={true} backdrop={true}>
            <SideNavContainer.Header className="text-light" closeVariant="white" closeButton>
                <SideNavContainer.Title>Menu</SideNavContainer.Title>
            </SideNavContainer.Header>
            <SideNavContainer.Body>
                <Nav className="flex-column">
                    <NavLink key="All" className="nav-link" to="/products/">All Products</NavLink>
                    {productTypes.map((name) => {
                        return (<NavLink key={name} className="nav-link" to={`/products/${slugify(name)}`} >{name}</NavLink>)
                    })}
                </Nav>
            </SideNavContainer.Body>
        </SideNavContainer>
    )
}