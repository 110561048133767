import React from 'react'
import {Nav, Row, Col} from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import { InlineIcon } from '@iconify/react'
import { FooterStyled, Gst, SocTitle, SocLink, FooterCopRow, PolicyLinks, SectionTitle, PaymentsLogo } from './footer.styled'

const Footer = () => {
    return(
        <FooterStyled fluid>
            <Row>
                <Col sm={12} lg={2} className="mb-4 d-flex flex-column">
                    <span><InlineIcon icon="twemoji:flag-for-flag-india" /> Chennai</span>
                    <b>Rayhan Attar Kadai</b>
                    <span className="d-block">#2, Thiruvalluvar Salai 3rd Street, Nesapakkam, <br />Chennai - 600078.</span>
                    <Gst className="d-block">GST: 33BORPA1450A1ZN</Gst>
                </Col>
                <Col sm={12} lg={6} className="d-flex mb-4">
                    <Row>
                        <Col className="mb-3">
                            <div className="d-flex flex-column mb-3">
                                <SocTitle>Whatsapp</SocTitle>
                                <SocLink href="https://signupforservices.com/whatsapp/optin/?bId=38de1476-8f10-4e82-a959-6de3401c39aa&bName=Rayhan&s=URL&lang=en_US" target="_blank" rel="noopener noreferrer">Open Whatsapp chat</SocLink>
                            </div>
                            <div className="d-flex flex-column align-self-end">
                                <SocTitle>Telegram</SocTitle>
                                <SocLink href="https://t.me/RayhanAttarKadai" target="_blank" rel="noopener noreferrer">@RayhanAttarKadai</SocLink>
                            </div>
                        </Col>
                        <Col className="mb-3">
                            <div className="d-flex flex-column mb-3">
                                <SocTitle>Facebook</SocTitle>
                                <SocLink href="https://fb.me/in.rayhan" target="_blank" rel="noopener noreferrer">@in.rayhan</SocLink>
                            </div>
                            <div className="d-flex flex-column align-self-end">
                                <SocTitle>Instagram</SocTitle>
                                <SocLink href="https://instagram.com/rayhan.in" target="_blank" rel="noopener noreferrer">@rayhan.in</SocLink>
                            </div>
                        </Col>
                        <Col className="mb-3">
                            <div className="d-flex flex-column mb-3">
                                <SocTitle>Mobile</SocTitle>
                                <SocLink href="tel:+919566237989" target="_blank" rel="noopener noreferrer">(+91) 9566284128</SocLink>
                            </div>
                            <div className="d-flex flex-column">
                                <SocTitle>Email</SocTitle>
                                <SocLink href="mail:contact@rayhan.in" target="_blank" rel="noopener noreferrer">contact@rayhan.in</SocLink>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} lg={4} className="d-flex flex-column">
                    <SectionTitle>Supported Payments</SectionTitle>
                    <PaymentsLogo>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/visa.svg" alt="visa logo" placeholder="blurred" height={22} />
                        </div>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/mastercard.svg" alt="master card logo" placeholder="blurred" height={22} />
                        </div>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/Maestro.svg" alt="maestro logo" placeholder="blurred" height={22} />
                        </div>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/amex.svg" alt="Amex logo" placeholder="blurred" height={22} />
                        </div>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/RuPay.svg" alt="rupay logo" placeholder="blurred" height={22} />
                        </div>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/UPI.svg" alt="UPI logo" placeholder="blurred" height={22} />
                        </div>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/freecharge.svg" alt="freecharge logo" placeholder="blurred" height={22} />
                        </div>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/GooglePay.svg" alt="gpay logo" placeholder="blurred" height={22} />
                        </div>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/PhonePe.svg" alt="phonepe logo" placeholder="blurred" height={22} />
                        </div>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/Paytm.svg" alt="phonepe logo" placeholder="blurred" height={22} />
                        </div>
                        <div className="paymentLogo-item">
                            <StaticImage src="../../images/paymentLogos/MobiKwik.svg" alt="MobiKwik logo" placeholder="blurred" height={22} />
                        </div>
                    </PaymentsLogo>
                </Col>
            </Row>
            <FooterCopRow className="mt-5 d-flex">
                <Col sm={{ span: 12, order: 'last' }} lg={{ span: 6, order: 'first' }} className="d-flex flex-column flex-md-row">
                    <span>Copyright © Rayhan - 2021</span>
                    <span className="d-none d-md-flex"> - </span>
                    <span>Designed by Ajmal Hussain Hy</span>
                </Col>
                <Col sm={{ span: 12, order: 'first' }} lg={{ span: 6, order: 'last' }} className="d-flex justify-content-start justify-content-md-end">
                    <Nav className="d-flex">
                        <PolicyLinks to={'/policy/privacy'}>Privacy Policy</PolicyLinks>
                        <PolicyLinks to={'/policy/shipping'}>Shipping and Delivery</PolicyLinks>
                        <PolicyLinks to={'/policy/terms'}>Terms and Conditions</PolicyLinks>
                        <PolicyLinks to={'/policy/authenticity'}>Authenticity</PolicyLinks>
                    </Nav>
                </Col>
            </FooterCopRow>
            
        </FooterStyled>
    )
}

export default Footer 